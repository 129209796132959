import { createSlice } from "@reduxjs/toolkit";

const clientSlice = createSlice({
    name: "client",
    initialState: { client_count: 0, client_list: [] },
    reducers: {
        incrClientCount(state, action) {
            state.client_count += action.payload;
        },
        addClientList(state, action) {
            state.client_list = action.payload;
        }
    },
});

export const { incrClientCount, addClientList } = clientSlice.actions;
export default clientSlice.reducer;
