import React, { useState, useEffect } from "react";
import {
  TableWrapper,
  TableHeading,
  PermissionText,
  HeadingCell,
  TableRows,
  PaginationContainer,
  ListFooterWrapper,
  FooterText,
  TableInfoText,
} from "./ListTable.styled";
import TableRow from "../../components/TableRow/TableRow";
import { getClientList } from "../../apis/ApiServerRequests";
import { connect, useSelector } from "react-redux";
import Pagination from "../../components/Pagination/Pagination";
import { setActiveList } from "../../store/slices/A11ySlice";
import useKeyPress from "../../hooks/useKeyPress";
import SkeletonLoader from "../../components/SkeletonLoader/SkeletonLoader";
import NoSearchFound from "../../components/NoSearchFound/NoSearchFound";
import Button from "../../components/Button/Button";
import speechFormat from "../../helpers/speechFormat";
import useSpeechSynthesis from "../../hooks/useSpeechSynthesis";
import { addClientList } from "../../store/slices/ClientSlice";
import { useDispatch } from "react-redux";

const ListTable = ({
  client_count,
  client_list,
  searchInput,
  setSearchInput,
  is_contrast
}) => {
  const { speak, speaking, cancelSpeaking } = useSpeechSynthesis(false);

  const perPage = 7 + Math.min(2, Math.ceil((window.innerHeight - 720) / 60));
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [loading, setLoading] = useState(true);
  const [reading, setReading] = useState(false);

  const [itemList, setItemList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  const dispatch = useDispatch();

  const active_list = useSelector((state) => state?.a11y?.active_list);
  // const overlay = useSelector((state) => state?.a11y?.overlay);

  // useEffect(() => {
  //   setSelectedRow(null);
  // }, [overlay]);

  useEffect(() => {
    if (!speaking && reading) setReading(false);
  }, [speaking]);

  useEffect(() => {
    if (selectedRow !== null) dispatch(setActiveList("client_list"));
  }, [selectedRow]);

  useEffect(() => {
    if (active_list !== "client_list") setSelectedRow(null);
  }, [active_list]);

  useEffect(() => {
    if (client_list.length === 0) {
      setLoading(true);
      getClientList()
        .then(({ data }) => {
          setClientList(data);
          setTotalPages(Math.ceil(data?.length ? data?.length / perPage : 0));
          dispatch(addClientList(data))
        })
        .finally(() => {
          setLoading(false);
        });
    } 
    else {
      let data = client_list
      setClientList(data);
      setTotalPages(Math.ceil(data?.length ? data?.length / perPage : 0));
      setLoading(false);
    }
  }, [client_count, client_list]);

  useEffect(() => {
    let filterList = clientList?.filter(({ pharmaName }) =>
      pharmaName.toLowerCase().includes((searchInput || "").toLowerCase())
    );
    let activeList = filterList
      ?.sort((a, b) => (a?.pharmaName < b?.pharmaName ? -1 : 1))
      ?.slice((currentPage - 1) * perPage, currentPage * perPage);

    setItemList(activeList);
    setTotalPages(
      Math.ceil(filterList?.length ? filterList?.length / perPage : 0)
    );
  }, [searchInput, currentPage, clientList]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchInput]);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleArrowUP = () => {
    if (active_list === "client_list")
      setSelectedRow(Math.max(0, selectedRow - 1));
  };

  const handleArrowDown = () => {
    let l = itemList.length;
    if (active_list === "client_list")
      setSelectedRow(Math.min(l - 1, selectedRow + 1));
  };

  const handleClientListRead = () => {
    if (reading) {
      cancelSpeaking();
      setReading(false);
      return;
    }
    let speech = "";
    itemList.forEach(({ pharmaName }, i) => {
      speech += speechFormat({
        key: `Alt + ${i + 1}`,
        action: "select",
        item: pharmaName,
      });
      speech += ". ";
    });
    setReading(true);
    speak(speech);
  };

  useKeyPress("C", [], handleClientListRead);
  useKeyPress("ArrowUp", [], handleArrowUP);
  useKeyPress("ArrowDown", [], handleArrowDown);
  useKeyPress("K", ["altKey"], () => setSearchInput(""));

  return (
    <>
      <TableWrapper>
        <TableHeading>
          <HeadingCell is_contrast={is_contrast} width={"10%"}>
            Company ID
          </HeadingCell>
          <HeadingCell is_contrast={is_contrast} width={"20%"}>
            Company Name <span>*</span>
          </HeadingCell>
          <HeadingCell is_contrast={is_contrast} width={"10%"}>
            Members <span>*</span>
          </HeadingCell>
          <HeadingCell is_contrast={is_contrast} width={"20%"} thres={1300}>
            Business Contact
          </HeadingCell>
          <HeadingCell is_contrast={is_contrast} width={"20%"} thres={1600}>
            Technical Contact
          </HeadingCell>
          <HeadingCell is_contrast={is_contrast} width={"10%"}>
            Entitlements <span>*</span>
          </HeadingCell>
          <HeadingCell width={"10%"} is_contrast={is_contrast}>
            File Storage <span>*</span>
          </HeadingCell>
        </TableHeading>
        <TableRows>
          {loading ? (
            <div style={{ width: "100%" }}>
              <SkeletonLoader count={perPage} height={60} />
            </div>
          ) : (
            <>
              {searchInput && itemList.length === 0 ? (
                <NoSearchFound
                  height={"336px"}
                  resetSearch={() => setSearchInput("")}
                />
              ) : (
                itemList.map((row, index, array) => (
                  <TableRow
                    key={index}
                    index={index}
                    row={row}
                    islast={index === array.length - 1}
                    onSelectRow={setSelectedRow}
                    isSelected={selectedRow === index}
                  />
                ))
              )}
            </>
          )}
        </TableRows>
      </TableWrapper>
      <TableInfoText>
        <span>*</span>Shortcuts for the selected row are{" "}
        <span>Show Details [1]</span> &nbsp; , <span>Manage users [2]</span>
        &nbsp;, <span>Manage Entitlements [3]</span> &nbsp; & <span>SharePoint Subsite [4]</span>{" "}
      </TableInfoText>
      <ListFooterWrapper>
        <FooterText>
          <Button
            type="ghost"
            text={reading ? "Cancel Read List" : "Read List"}
            accessKeyText="[ C ]"
            buttonStyle={{ padding: "4px 0px" }}
            data-speech={speechFormat({
              key: "C",
              action: reading ? "Cancel Reading of" : "read",
              item: "Company List",
            })}
            onClick={handleClientListRead}
          />
        </FooterText>
        <PaginationContainer>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onFirstPage={() => setCurrentPage(1)}
            onLastPage={() => setCurrentPage(totalPages)}
            onPrev={handlePrevPage}
            onNext={handleNextPage}
            firstAccessKey={{
              text: "[Ctrl + ;]",
              key: ";",
              subkey: ["ctrlKey"],
              speech: speechFormat({
                key: "Control + Semicolon",
                action: "goto",
                item: "First page of Company list",
              }),
            }}
            prevAccessKey={{
              text: "[Ctrl + ,]",
              key: ",",
              subkey: ["ctrlKey"],
              speech: speechFormat({
                key: "Control + comma",
                action: "goto",
                item: "Previous page of Company list",
              }),
            }}
            nextAccessKey={{
              text: "[Ctrl + .]",
              key: ".",
              subkey: ["ctrlKey"],
              speech: speechFormat({
                key: "Control + fullstop",
                action: "goto",
                item: "Next page of Company list",
              }),
            }}
            lastAccesskey={{
              text: "[Ctrl + ']",
              key: "'",
              subkey: ["ctrlKey"],
              speech: speechFormat({
                key: "Control + single quote",
                action: "goto",
                item: "Last page of Company list",
              }),
            }}
          />
        </PaginationContainer>
      </ListFooterWrapper>
    </>
  );
};

ListTable.propTypes = {};

ListTable.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    client_count: state?.client?.client_count,
    client_list: state?.client?.client_list,
    is_contrast: state?.a11y?.high_contrast,
  };
};

export default connect(mapStateToProps)(ListTable);
