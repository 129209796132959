import React, { useEffect, useState } from "react";
import {
  Body,
  DocumentBody,
  Frame,
  HeadRight,
  HeadTitle,
  HeaderWrapper,
  Left,
  Middle,
  PaginationContainer,
  PairWrapper,
  PreviewBody,
  PreviewFooter,
  PreviewHeader,
  PreviewSubtext,
  PreviewTitle,
  Right,
  SearchContainer,
  Wrapper,
} from "./ConfigurationModal.styled";
import Button from "../../components/Button/Button";
import { PairTitle, ButtonWrapper } from "./ConfigurationModal.styled";
import Search from "../../components/Search/Search";
import Pagination from "../../components/Pagination/Pagination";
import InputField from "../../components/InputField/InputField";
import {
  getPreviewPDFFile
} from "../../apis/ApiServerRequests";
import { Document, Page, pdfjs } from "react-pdf";
import { connect } from "react-redux";
import SkeletonLoader from "../../components/SkeletonLoader/SkeletonLoader";
import useKeyPress from "../../hooks/useKeyPress";
import NoSearchFound from "../../components/NoSearchFound/NoSearchFound";
import speechFormat from "../../helpers/speechFormat";
import NoPreviewFound from "../../components/NoPreviewFound/NoPreviewFound";
import SharePointInfo from "../../components/SharePointInfo/SharePointInfo";
import Accordion from "../../components/Accordion/Accordion";
import FolderIcon from "../../assets/svgs/FolderIcon";
import DeleteIcon from "../../assets/svgs/DeleteIcon";
import { useSelector } from "react-redux";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const ConfigurationModal = ({
  onClose,
  templateType,
  // setTemplateType,
  // selectDocFile,
  enablePreview,
  // pairComponents,
  title,
  protocolDocOptions,
  is_contrast,
  protocolDocLoading,
  overlayName,
  // pharmaName,
  setIsFolder,
  setSelectedConfigurationFile,
  array,
  setArray
}) => {
  const perPage = 4;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [loading, setLoading] = useState(false);
  const [fileBlob, setFileBlob] = useState(null);
  const [numPages, setNumPages] = useState(null);

  const [input, setInput] = useState("");
  const [itemList, setItemList] = useState([]);
  const [filenameList, setFilenameList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedAccordion, setSelectedAccordion] = useState(-1);

  const client_list = useSelector((state) => state?.client?.client_list);

  const addElement = (element) => {
    setArray((prevArray) => [...prevArray, element]);
  };

  const removeElement = (element) => {
    setArray((prevArray) => prevArray.filter((item) => item !== element));
  };

  const handleSelection = (i) => {
    setSelectedItem(i);
  };

  useEffect(() => {
    setLoading(true);
    setTotalPages(0);
    setSelectedItem(null);
    if (templateType === "select_pharmacy") {
      const respData = client_list.map((client) => client.pharmaName);
      setFilenameList(respData);
      setTotalPages(
        Math.ceil(respData?.length ? respData?.length / perPage : 0)
      );
      setLoading(false);
    } else if (templateType === "configuration_Doc") {
      setLoading(false);
      setFilenameList(protocolDocOptions);
      setTotalPages(
        Math.ceil(
          protocolDocOptions?.length ? protocolDocOptions?.length / perPage : 0
        )
      );
      setLoading(protocolDocLoading);
    }
    setFileBlob(null);
    setInput("");
  }, [templateType]);

  useEffect(() => {
    setFileBlob(null);
    const handleDownloadAndDisplay = (name, isFolder = false) => {
      let template = "Source Templates";
      if (templateType === "destination_templates") {
        template = "Destination Templates";
        getPreviewPDFFile(template, "Clinical",name).then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          setFileBlob(blob);
        });
      } 
      else if (templateType === "configuration_Doc") {
        let template = "Style Configurations";
        getPreviewPDFFile(template, "Clinical" ,name).then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          setFileBlob(blob);
        });
      } else {
        getPreviewPDFFile(template, "Clinical" ,name).then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          setFileBlob(blob);
        });
      }
    };

    if (selectedItem != null) {
      if (
        templateType === "source_templates" ||
        templateType === "protocol_Doc" ||
        templateType === "configuration_Doc"
      ) {
        handleDownloadAndDisplay(
          `${itemList[selectedAccordion]?.versions[selectedItem].name}`,
          itemList[selectedAccordion]?.versions[selectedItem]?.type === "folder"
        );
        setIsFolder(
          itemList[selectedAccordion]?.versions[selectedItem]?.type === "folder"
        );
      }

      if (templateType === "select_pharmacy") {
        handleEsc();
      }
    }
  }, [selectedItem]);

  useEffect(() => {
    if (
      templateType === "source_templates" ||
      templateType === "destination_templates" ||
      templateType === "protocol_Doc" ||
      templateType === "configuration_Doc"
    ) {
      let filterList = filenameList?.filter((item) =>
        item.name.toLowerCase().includes((input || "").toLowerCase())
      );
      let activeList = filterList?.slice(
        (currentPage - 1) * perPage,
        currentPage * perPage
      );

      setItemList(activeList);
      setTotalPages(
        Math.ceil(filterList?.length ? filterList?.length / perPage : 0)
      );
    } else {
      let filterList = filenameList?.filter((item) =>
        item.toLowerCase().includes((input || "").toLowerCase())
      );
      let activeList = filterList?.slice(
        (currentPage - 1) * perPage,
        currentPage * perPage
      );

      setItemList(activeList);
      setTotalPages(
        Math.ceil(filterList?.length ? filterList?.length / perPage : 0)
      );
    }
  }, [input, currentPage, filenameList]);

  useEffect(() => {
    setCurrentPage(1);
  }, [input]);

  useEffect(() => {
    setSelectedItem(null);
  }, [currentPage]);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const fetchInput = (val) => {
    setInput(val);
  };

  const handleEnter = () => {
    const joinedArray = array.join(',');
    setSelectedConfigurationFile(joinedArray);
    onClose(true);
  };
    
  const handleEsc = () => {
    const joinedArray = array.join(',');
    setSelectedConfigurationFile(joinedArray);
    onClose(false)
  };

  useKeyPress("Escape", [], handleEsc, true, overlayName, true);
  useKeyPress("Enter", [], handleEnter, true, overlayName, true);
  // useKeyPress("1", ["ctrlKey"], () => {handleSelection(0);addElement(itemList[selectedAccordion]?.versions[0].name)}, true, overlayName);
  // useKeyPress("2", ["ctrlKey"], () => handleSelection(1), true, overlayName);
  // useKeyPress("3", ["ctrlKey"], () => handleSelection(2), true, overlayName);
  // useKeyPress("4", ["ctrlKey"], () => handleSelection(3), true, overlayName);
  useKeyPress("K", ["altKey"], () => setInput(""), true, overlayName);
  // useKeyPress("A", ["altKey"], addPair, true, overlayName, true);

  return (
    <Wrapper width={"1296"}>
      <HeaderWrapper>
        <HeadTitle>{title.toUpperCase()}</HeadTitle>
        <HeadRight>
          <Button
            type={"ghost"}
            text={"Cancel"}
            onClick={handleEsc}
            accessKeyText={"[Esc]"}
            data-speech={speechFormat({
              key: "Escape",
              action: "close",
              item: "Dialog",
            })}
          />
          {templateType === "select_pharmacy" || (
            <Button
              disabled={!array.length}
              type={"secondary"}
              text={enablePreview ? "Done" : "Continue"}
              onClick={handleEnter}
              accessKeyText={"[Enter]"}
              speech={speechFormat({
                key: "Enter",
                action: "select",
                item: "Doc",
              })}
            />
          )}
        </HeadRight>
      </HeaderWrapper>
      <Body>
          <Left>
            <PairWrapper>
              <PairTitle>Select Styling Configuration Doc</PairTitle>
              {array.map((pair, index) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "24px",
                  }}
                >
                  <InputField
                    key={index}
                    onChange={(e) => {}}
                    value={pair}
                    // onClick={() => handleInputClick(index)}
                    width={"220"}
                  />
                  <div>
                    <Button
                      type="ghost"
                      // accessKeyText={`[Ctrl + Alt + ${index + 1}]`}
                      // buttonStyle={{ padding: "0px 0px", height: "26px" }}
                      icon={<DeleteIcon />}
                      onClick={() => removeElement(pair)}
                      // data-speech={speechFormat({
                      //   key: "",
                      //   action: "",
                      //   item: "",
                      // })}
                    />
                  </div>
                </div>
              ))}
            </PairWrapper>
          </Left>
        <Middle preview={enablePreview}>
          <SearchContainer>
            <Search
              wrapperStyle={{
                width: "420px",
              }}
              width={"420"}
              placeholdertext={"Search Configuration File"}
              righticons={true}
              onChange={fetchInput}
              value={input}
              searchCommand={{
                text: "X",
                key: "X",
                subkey: [],
              }}
              voiceCommand={{
                text: "[ Alt + X ]",
                key: "X",
                subkey: ["altKey"],
              }}
              overlayName={overlayName}
              isOverlay={true}
              speech={speechFormat({
                action: "search",
                item: "Docs",
              })}
            />
          </SearchContainer>
          <DocumentBody>
            {loading ? (
              <div style={{ width: "100%" }}>
                <SkeletonLoader count={perPage} height={74} />
              </div>
            ) : (
              <>
                {templateType === "configuration_Doc" &&
                  filenameList.length === 0 && <SharePointInfo />}
                {input && filenameList.length > 0 && itemList.length === 0 ? (
                  <NoSearchFound
                    height={"336px"}
                    resetSearch={() => setInput("")}
                  />
                ) : (
                  itemList.map((item, index) => {
                    return (
                      <Accordion
                        title={item.name}
                        key={index}
                        sno={index}
                        content={item?.versions}
                        icon={FolderIcon()}
                        selectedItem={selectedItem}
                        selectedAccordion={selectedAccordion}
                        setSelectedAccordion={setSelectedAccordion}
                        handleSelection={handleSelection}
                        overlayName={templateType}
                        isOverlay={true}
                        templateType={templateType}
                        array={array}
                        setArray={setArray}
                        addElement={addElement}
                        removeElement={removeElement}
                      />
                    );
                  })
                )}
              </>
            )}
          </DocumentBody>
          <PaginationContainer>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onFirstPage={() => setCurrentPage(1)}
              onLastPage={() => setCurrentPage(totalPages)}
              onPrev={handlePrevPage}
              onNext={handleNextPage}
              firstAccessKey={{
                text: "[;]",
                key: ";",
                subkey: [],
                speech: speechFormat({
                  key: "Semicolon",
                  action: "goto",
                  item: "First page",
                }),
              }}
              prevAccessKey={{
                text: "[,]",
                key: ",",
                subkey: [],
                speech: speechFormat({
                  key: "comma",
                  action: "goto",
                  item: "Previous page",
                }),
              }}
              nextAccessKey={{
                text: "[.]",
                key: ".",
                subkey: [],
                speech: speechFormat({
                  key: "fullstop",
                  action: "goto",
                  item: "Next page",
                }),
              }}
              lastAccesskey={{
                text: "[']",
                key: "'",
                subkey: [],
                speech: speechFormat({
                  key: "single quote",
                  action: "goto",
                  item: "Last page",
                }),
              }}
              isOverlay={true}
              overlayName={templateType}
            />
          </PaginationContainer>
        </Middle>

        {enablePreview ? (
          <Right>
            <PreviewHeader>
              <PreviewTitle>Preview of the Selected Doc</PreviewTitle>
              {/* <PreviewSubtext is_contrast={is_contrast}>
                {templateType === "select_pharmacy" || templateType === "configuration_Doc" && selectedItem >= 0 ? `${filenameList[selectedItem]}` : `${itemList[selectedAccordion]?.name} > ${templateType === "destination_templates" ? "Destination Document" : "Source Document"} - ${itemList[selectedAccordion]?.versions[selectedItem]}.docx`}
              </PreviewSubtext> */}
            </PreviewHeader>
            <PreviewBody>
              {selectedItem === null ? (
                <NoPreviewFound />
              ) : (
                <>
                  {(fileBlob && (
                    <Document
                      file={fileBlob}
                      onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                    >
                      {Array.from(new Array(numPages), (el, index) => (
                        <Page
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                          renderAnnotationLayer={false}
                          renderTextLayer={false}
                        />
                      ))}
                    </Document>
                  )) || (
                    <div style={{ width: "100%" }}>
                      <SkeletonLoader height={340} />
                    </div>
                  )}
                </>
              )}
            </PreviewBody>
            {selectedItem ? (
              <PreviewFooter>
                <Button
                  type={"ghost"}
                  text={"Scroll Up"}
                  buttonStyle={{ padding: "20px 0px" }}
                  accessKeyText={"[Page Up]"}
                />
                <Button
                  type={"ghost"}
                  text={"Scroll Down"}
                  buttonStyle={{ padding: "20px 0px" }}
                  accessKeyText={"[Page Dn]"}
                />
              </PreviewFooter>
            ) : null}
          </Right>
        ) : null}
      </Body>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    is_contrast: state?.a11y?.high_contrast,
  };
};

export default connect(mapStateToProps)(ConfigurationModal);
