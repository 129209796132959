import React, { useEffect, useState } from "react";
import {
  AddClientModalWrapper,
  HeaderWrapper,
  HeadTitle,
  HeadRight,
  Title,
  MemberDetailWrapper,
  AddMemberWrapper,
  AddMemberLabel,
  MemberDetailContainer,
  ExtraWrapper,
  RadioButtonWrapper,
  ErrorWrapper,
} from "./AddClientModal.styled";
import Button from "../../components/Button/Button";
import InputField from "../../components/InputField/InputField";
import DeleteIcon from "../../assets/svgs/DeleteIcon";
import { createClient, getClientList, searchUser } from "../../apis/ApiServerRequests";
import { useDispatch } from "react-redux";
import { addClientList, incrClientCount } from "../../store/slices/ClientSlice";
import DropDownNew from "../../components/Dropdown/Dropdown";
import CompanyBadgeIcon from "../../assets/svgs/CompanyBadgeIcon";
import Emailcon from "../../assets/svgs/Emailcon";
import CompanyAddressIcon from "../../assets/svgs/CompanyAddressIcon";
import PhoneIcon from "../../assets/svgs/PhoneIcon";
import BuildIcon from "../../assets/svgs/BuildIcon";
import validateEmail from "../../helpers/validateEmail";
import useKeyPress from "../../hooks/useKeyPress";
import BusinessIcon from "../../assets/svgs/BusinessIcon";
import UserIcon from "../../assets/svgs/UserIcon";
import speechFormat from "../../helpers/speechFormat";
import SearchAutocomplete from "../../components/AutoCompleteSearch/AutoCompleteSearch";
import { AddEntitlementWrapper, EntitlementDetailWrapper, SharepointWrapper } from "./AddClientModal.styled";
import EntitlementIcon from "../../assets/svgs/EntitlementIcon";
import SelectProtocolIcon from "../../assets/svgs/SelectProtocolIcon";
import RadioButtonGroup from "../../components/RadioButtonGroup/RadioButtonGroup";

const specialChars = /[!@#$%^&*(),.?":{}|<>\-`~_+=;\[\]\/'']/;


const sharepointSiteDetails = () => [
  {label: 'Custom Sharepoint URL', value: '', inputCommand: {
    text: "[Alt+Ctrl+1]",
    key: "1",
    subkey: ["altKey", "ctrlKey"],
  }},
  {label: 'Custom Sharepoint Tenant Name', value: '', inputCommand: {
    text: "[Alt+Ctrl+2]",
    key: "2",
    subkey: ["altKey", "ctrlKey"],
  }},
  {label: 'Custom Sharepoint Tenant ID', value: '', inputCommand: {
    text: "[Alt+Ctrl+3]",
    key: "3",
    subkey: ["altKey", "ctrlKey"],
  }},
  {label: 'Custom Sharepoint Client ID', value: '', inputCommand: {
    text: "[Alt+Ctrl+4]",
    key: "4",
    subkey: ["altKey", "ctrlKey"],
  }},
  {label: 'Custom Sharepoint Client Secret', value: '', inputCommand: {
    text: "[Alt+Ctrl+5]",
    key: "5",
    subkey: ["altKey", "ctrlKey"],
  }}
]


const AddClientModal = ({ onClose, onSuccess, overlayName }) => {
  const dispatch = useDispatch();
  const [pharmaName, setPharmaName] = useState("");
  const [address, set_address] = useState("");
  const [business_contact_email, set_business_contact_email] = useState("");
  const [business_contact_name, set_business_contact_name] = useState("");
  const [technical_contact_name, set_technical_contact_name] = useState("");
  const [technical_contact_email, set_technical_contact_email] = useState("");
  const [userDetail, setUserDetail] = useState([]);
  const [entitlement, setEntitlement] = useState([]); //["ICF","Narratives"]
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [focusBusinessContact, setFocusBusinessContact] = useState(false);
  const [focusTechnicalContact, setfocusTechnicalContact] = useState(false);
  const [focusAddUser, setFocusAddUser] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [sharepointSite, setSharepointSite] = useState(sharepointSiteDetails())

  const [debounce, setDebounce] = useState(null);
  const [emailoptions, setEmailOptions] = useState([]);
  // const [showOptions, setShowOptions] = useState(true);

  const addEntitlement = (entitlementValue) => {
    if (!entitlement.includes(entitlementValue)) setEntitlement((prevEntitlement) => [...prevEntitlement, entitlementValue]);
  };

  const changeSelectedValue = (index) => {
    setSelectedIndex(index)
  }


  const handleSharepointDetails = (e, index) => {
    const currentState = [...sharepointSite]
    currentState[index].value = e.target.value;
    setSharepointSite(currentState)
  }

  const addMemberfunction = (value) => {
    if (value?.email && value?.name) {
      setUserDetail([
        ...userDetail, 
        {
          name: value?.name,
          email: value?.email,
          role: "owner",
          showOptions: false,
        },
      ]);
    } else if (value?.email) {
      setUserDetail([
        ...userDetail,
        { email: value?.email, role: "owner", showOptions: false },
      ]);
    } else if (value?.name) {
      setUserDetail([
        ...userDetail,
        { name: value?.name, role: "owner", showOptions: false },
      ]);
    }
  };
  const addBusinessUser = (value) => {
    if (value?.email && value?.name) {
      set_business_contact_email(value?.email);
      set_business_contact_name(value?.name);
    } else if (value?.email) {
      set_business_contact_email(value?.email);
      set_business_contact_name("");
    } else if (value?.name) {
      set_business_contact_name(value?.name);
      set_business_contact_email("");
    }
  };

  const addTechnicalUser = (value) => {
    if (value?.email && value?.name) {
      set_technical_contact_name(value?.name);
      set_technical_contact_email(value?.email);
    } else if (value?.email) {
      set_technical_contact_email(value?.email);
      set_technical_contact_name("");
    } else if (value?.name) {
      set_technical_contact_name(value?.name);
      set_technical_contact_email("");
    }
  };

  const deleteMemberfunction = (itemIndex) => {
    let data = [...userDetail];
    data.splice(itemIndex, 1);
    setUserDetail(data);
  };
  const deleteEntitlementfunction = (itemIndex) => {
    let data = [...entitlement];
    data.splice(itemIndex, 1);
    setEntitlement(data);
  };

  const handleInputChange = (e, callback) => {
    callback(e.target.value);
  };

  const handleValidation = () => {
    let valid = true;

    if (userDetail.length < 1) valid = false;
    userDetail.forEach((e) => {
      let { name, email, role } = e;
      if (
        !(
          name &&
          business_contact_email &&
          business_contact_name &&
          technical_contact_name &&
          technical_contact_email &&
          entitlement.length > 0 &&
          validateEmail(email) &&
          role
        )
      )
        valid = false;
    });
    if (!pharmaName) {
      valid = false;
    }
    if (pharmaName) {

      if (specialChars.test(pharmaName)) {
        valid = false;
      }
    }
    if (emailError) {
      valid = false;
    }

    if (entitlement.length < 1) valid = false

    if (selectedIndex === 1) valid = !sharepointSite.some(item => item.value === '')

    if (selectedIndex === null) valid = false

    return valid;
  };

  useEffect(() => {
    if (pharmaName) {
  
      if (specialChars.test(pharmaName)) {
        setErrorMessage('*Special characters are not allowed');
      } else {
        setErrorMessage('');
      }
    }
  }, [pharmaName]);

  const handleUserInputChange = (e, index, type) => {
    if (type === "email") {
      let data = [...userDetail];
      data[index] = {
        ...data[index],
        email: e.target.value,
        showOptions: true,
      };
      setUserDetail(data);
    }

    if (type === "name") {
      let data = [...userDetail];
      data[index] = { ...data[index], name: e.target.value };
      setUserDetail(data);
    }

    if (type === "role" && e?.toLowerCase()) {
      let data = [...userDetail];
      data[index] = { ...data[index], role: e?.toLowerCase() };
      setUserDetail(data);
    }
  };

  const handleAddClient = () => {
    if (!handleValidation()) return;

    let data = {
      users: userDetail,
      entitlement_type: entitlement,
    };
    let pharma_details = {
      address,
      business_contact_name,
      business_contact_email,
      technical_contact_name,
      technical_contact_email,
      custom_domain: selectedIndex === 1,
    };

    if (selectedIndex === 1) {
      pharma_details = {
        address,
        business_contact_name,
        business_contact_email,
        technical_contact_name,
        technical_contact_email,
        custom_domain: selectedIndex === 1,
        sharepoint_client_url: sharepointSite[0].value,
        tenant: sharepointSite[1].value,
        tenant_id: sharepointSite[2].value,
        sharepoint_client_id: sharepointSite[3].value,
        sharepoint_client_secret: sharepointSite[4].value
      }
    }
    setLoading(true);
    onSuccess({ loading: true });
    createClient(pharmaName, data, pharma_details)
      .then(() => {
        dispatch(addClientList([]));
        dispatch(incrClientCount(1));
        setUserDetail([{}]);
        setPharmaName("");
        set_address("");
        set_business_contact_email("");
        set_business_contact_name("");
        set_technical_contact_name("");
        set_technical_contact_email("");
        setSharepointSite(sharepointSiteDetails())
        onClose();
        onSuccess({ loading: false });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    return () => {
      setUserDetail([]);
      setPharmaName("");
      set_address("");
      set_business_contact_email("");
      set_business_contact_name("");
      set_technical_contact_name("");
      set_technical_contact_email("");
    };
  }, []);

  useKeyPress("Escape", [], onClose, true, overlayName, true);
  useKeyPress(
    "1",
    ["altKey"],
    () => deleteMemberfunction(0),
    true,
    overlayName
  );
  useKeyPress(
    "2",
    ["altKey"],
    () => deleteMemberfunction(1),
    true,
    overlayName
  );
  useKeyPress(
    "3",
    ["altKey"],
    () => deleteMemberfunction(2),
    true,
    overlayName
  );
  useKeyPress(
    "4",
    ["altKey"],
    () => deleteMemberfunction(3),
    true,
    overlayName
  );
  useKeyPress(
    "5",
    ["altKey"],
    () => deleteMemberfunction(4),
    true,
    overlayName
  );
  useKeyPress(
    "6",
    ["altKey"],
    () => deleteMemberfunction(5),
    true,
    overlayName
  );
  useKeyPress(
    "7",
    ["altKey"],
    () => deleteMemberfunction(6),
    true,
    overlayName
  );
  useKeyPress(
    "8",
    ["altKey"],
    () => deleteMemberfunction(7),
    true,
    overlayName
  );
  useKeyPress(
    "9",
    ["altKey"],
    () => deleteMemberfunction(8),
    true,
    overlayName
  );

  useKeyPress(
    "1",
    ["ctrlKey"],
    () => deleteEntitlementfunction(0),
    true,
    overlayName
  );

  useKeyPress(
    "S",
    ["altKey"],
    () => changeSelectedValue(0),
    true,
    overlayName
  );

  useKeyPress(
    "C",
    ["altKey"],
    () => changeSelectedValue(1),
    true,
    overlayName
  );

  useKeyPress("+", [], addMemberfunction, true, overlayName);
  useKeyPress("Enter", [], handleAddClient, true, overlayName, true);
  // useKeyPress("S", [], () => setFocusAddUser(true), true, overlayName);
  // useKeyPress("B", ["altKey"], () => setFocusBusinessContact(true), true, overlayName);
  // useKeyPress("T", ["altKey"], () => setfocusTechnicalContact(true), true, overlayName);

  return (
    <AddClientModalWrapper>
      <HeaderWrapper>
        <HeadTitle>{"COMPANY CREATION"}</HeadTitle>
        <HeadRight>
          <Button
            type={"ghost"}
            text={"Cancel"}
            onClick={onClose}
            accessKeyText={"[Esc]"}
            data-speech={speechFormat({
              key: "Escape",
              action: "close",
              item: "Company Creation Dialog",
            })}
          />
          <Button
            type={"secondary"}
            text={loading ? "Saving..." : "Save Company"}
            onClick={handleAddClient}
            accessKeyText={"[Enter]"}
            disabled={!handleValidation() || loading}
            data-speech={speechFormat({
              key: "Enter",
              action: "create",
              item: "New company",
            })}
          />
        </HeadRight>
      </HeaderWrapper>
      <div
        style={{
          height: "100%",
          width: "100%",
          overflowY: "scroll",
          overflowX: "hidden",
          paddingBottom: "100px",
        }}
      >
        <InputField
          focus={true}
          label={"Enter Company Name"}
          labelStyle={{
            position: "absolute",
            padding: "4px 50px",
          }}
          inputStyle={{ paddingBottom: "10px", height: "34px" }}
          width={"584"}
          type={"text"}
          value={pharmaName}
          onChangefunction={(e) => handleInputChange(e, setPharmaName)}
          icon={CompanyBadgeIcon()}
        />
        {errorMessage && <ErrorWrapper><span>{errorMessage}</span></ErrorWrapper>}
        <ExtraWrapper>
          <Title>Company Address</Title>
          <InputField
            width={"584"}
            labelStyle={{
              position: "absolute",
              padding: "4px 50px",
            }}
            label={`Enter Company Address`}
            value={address}
            type={"text"}
            setError={setEmailError}
            inputStyle={{ paddingBottom: "10px", height: "34px" }}
            onChangefunction={(e) => handleInputChange(e, set_address)}
            icon={CompanyAddressIcon()}
          />
        </ExtraWrapper>
        <ExtraWrapper>
          <Title>Business Contact</Title>
          <div style={{ width: 584, marginBottom: "20px" }}>
            <SearchAutocomplete
              placeholder={"Search User to add as a Business Contact"}
              handleAddMember={addBusinessUser}
              searchCommand={{
                text: "[Alt+B]",
                key: "B",
                subkey: ["altKey"],
              }}
              isOverlay={true}
              overlayName={overlayName}
            />
          </div>
          {business_contact_name || business_contact_email ? (
            <div
              style={{
                display: "flex",
                width: "584px",
                justifyContent: "space-between",
              }}
            >
              <InputField
                width={"280"}
                labelStyle={{
                  position: "absolute",
                  padding: "4px 50px",
                }}
                label={`Business Contact Name`}
                value={business_contact_name}
                type={"text"}
                setError={setEmailError}
                inputStyle={{ paddingBottom: "10px", height: "34px" }}
                onChangefunction={(e) =>
                  handleInputChange(e, set_business_contact_name)
                }
                icon={BusinessIcon()}
              />
              <InputField
                width={"280"}
                labelStyle={{
                  position: "absolute",
                  padding: "4px 50px",
                }}
                label={`Business Contact Email`}
                value={business_contact_email}
                type={"text"}
                setError={setEmailError}
                inputStyle={{ paddingBottom: "10px", height: "34px" }}
                onChangefunction={(e) =>
                  handleInputChange(e, set_business_contact_email)
                }
                icon={Emailcon()}
              />
            </div>
          ) : null}
        </ExtraWrapper>
        <ExtraWrapper>
          <Title>Technical Contact</Title>
          <div style={{ width: 584, marginBottom: "20px" }}>
            <SearchAutocomplete
              placeholder={"Search User to add as a Technical Contact"}
              handleAddMember={addTechnicalUser}
              searchCommand={{
                text: "[Alt+T]",
                key: "T",
                subkey: ["altKey"],
              }}
              isOverlay={true}
              overlayName={overlayName}
            />
          </div>
          {technical_contact_name || technical_contact_email ? (
            <div
              style={{
                display: "flex",
                width: "584px",
                justifyContent: "space-between",
              }}
            >
              <InputField
                width={"280"}
                labelStyle={{
                  position: "absolute",
                  padding: "4px 50px",
                }}
                label={`Technical Contact Name`}
                value={technical_contact_name}
                type={"text"}
                setError={setEmailError}
                inputStyle={{ paddingBottom: "10px", height: "34px" }}
                onChangefunction={(e) =>
                  handleInputChange(e, set_technical_contact_name)
                }
                icon={BuildIcon()}
              />
              <InputField
                width={"280"}
                labelStyle={{
                  position: "absolute",
                  padding: "4px 50px",
                }}
                label={`Technical Contact Email`}
                value={technical_contact_email}
                type={"text"}
                setError={setEmailError}
                inputStyle={{ paddingBottom: "10px", height: "34px" }}
                onChangefunction={(e) =>
                  handleInputChange(e, set_technical_contact_email)
                }
                icon={Emailcon()}
              />
            </div>
          ) : null}
        </ExtraWrapper>
        <AddMemberWrapper>
          <Title>Users</Title>
          <div style={{ width: 584, marginBottom: "20px" }}>
            <SearchAutocomplete
              placeholder={"Search User to Add"}
              handleAddMember={addMemberfunction}
              searchCommand={{
                text: "[Alt+U]",
                key: "U",
                subkey: ["altKey"],
              }}
              isOverlay={true}
              overlayName={overlayName}
            />
          </div>
          <MemberDetailContainer>
            {userDetail.length > 0 &&
              userDetail.map((item, index) => {
                return (
                  <MemberDetailWrapper
                    bottomPadding={
                      index == userDetail.length - 1 ? "35" : false
                    }
                    style={{
                      paddingTop: index == 0 ? "0px" : "13px",
                      borderBottom:
                        index == userDetail.length - 1
                          ? "none"
                          : "1px solid #f2f2f2",
                    }}
                  >
                    <InputField
                      width={"280"}
                      labelStyle={{
                        position: "absolute",
                        padding: "4px 50px",
                      }}
                      label={`Enter User's Name`}
                      value={item.name}
                      type={"text"}
                      inputStyle={{
                        paddingBottom: "10px",
                        height: "34px"
                      }}
                      onChangefunction={(e) =>
                        handleUserInputChange(e, index, "name")
                      }
                      icon={UserIcon()}
                    />
                    <InputField
                      width={"280"}
                      labelStyle={{
                        position: "absolute",
                        padding: "4px 50px",
                      }}
                      label={`Enter User's Email`}
                      value={item.email}
                      type={"email"}
                      setError={setEmailError}
                      inputStyle={{
                        paddingBottom: "10px",
                        height: "34px"
                      }}
                      onChangefunction={(e) =>
                        handleUserInputChange(e, index, "email")
                      }
                      icon={Emailcon()}
                    />

                    <DropDownNew
                      width={"180"}
                      label={`Select User's Role`}
                      labelStyle={{
                        position: "absolute",
                        padding: "4px 50px",
                      }}
                      options={["owner", "member"]}
                      defaultText={`${item.role}`}
                      onChangefunction={(value) =>
                        handleUserInputChange(value, index, "role")
                      }
                      icon={CompanyBadgeIcon()}
                    />
                    <Button
                      type={"ghost"}
                      icon={DeleteIcon()}
                      text={""}
                      accessKeyText={`[ Alt + ${index + 1} ]`}
                      onClick={() => deleteMemberfunction(index)}
                      iconStyle={{ marginRight: "0px" }}
                      buttonStyle={{
                        paddingLeft: "0px",
                      }}
                      data-speech={speechFormat({
                        key: `Alt + ${index + 1}`,
                        action: "delete",
                        item: userDetail[index]?.email || "blank user row",
                      })}
                    />
                  </MemberDetailWrapper>
                );
              })}
          </MemberDetailContainer>
        </AddMemberWrapper>

        <AddEntitlementWrapper>
        <Title>Entitlement Types</Title>
          <div style={{ width: 584, marginBottom: "20px" }}>
            <SearchAutocomplete
              placeholder={"Add Entitlement"}
              handleAddMember={addEntitlement}
              searchCommand={{
                text: "[Alt+E]",
                key: "E",
                subkey: ["altKey"],
              }}
              rightIcon={EntitlementIcon()}
              searchEnabled = {false}
              enableAddButton={false}
              isOverlay={true}
              overlayName={overlayName}
            />
          </div>
          <MemberDetailContainer>
            {entitlement.length > 0 &&
              entitlement.map((item, index) => {
                return (
                  <EntitlementDetailWrapper
                    bottomPadding={
                      index == userDetail.length - 1 ? "15" : false
                    }
                    style={{
                      paddingTop: index == 0 ? "0px" : "13px",
                    }}
                  >
                 <InputField
                      width={"280"}
                      labelStyle={{
                        position: "absolute",
                        padding: "4px 50px",
                      }}
                      value={item}
                      type={"text"}
                      inputStyle={{
                        paddingBottom: "10px",
                        height: "34px"
                      }}
                      onChangefunction={(e) =>
                        handleUserInputChange(e, index, "name")
                      }
                      icon={EntitlementIcon()}
                    />
                    <Button
                      type={"ghost"}
                      icon={DeleteIcon()}
                      text={""}
                      accessKeyText={`[ Ctrl + ${index + 1} ]`}
                      onClick={() => deleteEntitlementfunction(index)}
                      iconStyle={{ marginRight: "0px" }}
                      buttonStyle={{
                        paddingLeft: "0px",
                      }}
                      data-speech={speechFormat({
                        key: `Ctrl + ${index + 1}`,
                        action: "delete",
                        item: entitlement[index] || "blank user row",
                      })}
                    />
                  </EntitlementDetailWrapper>
                );
              })}
          </MemberDetailContainer>
        </AddEntitlementWrapper>

        <div>
          <Title>SharePoint Site</Title>
            <RadioButtonWrapper>
              <RadioButtonGroup
                width={"280"}
                type={"radio"}
                options={[
                  {title: "Synterex Sharepoint", inputCommand: {
                    text: "[Alt+S]",
                    key: "S",
                    subkey: ["altKey"],
                  }}, 
                  {title: "Custom Sharepoint", inputCommand: {
                    text: "[Alt+C]",
                    key: "C",
                    subkey: ["altKey"],
                  }}
                ]}
                inputStyle={{ paddingBottom: "10px", height: "34px" }}
                selectedIndex={selectedIndex}
                onChange={(index) => changeSelectedValue(index)}
              />
            </RadioButtonWrapper>
        </div>

        {selectedIndex === 1 && <SharepointWrapper>
          {sharepointSite.map((item, index) => <InputField
            label={item.label}
            labelStyle={{
              position: "absolute",
              padding: "4px 50px",
            }}
            inputStyle={{ paddingBottom: "10px", height: "34px" }}
            width={"584"}
            type={"text"}
            value={item.value}
            onChangefunction={(e) => handleSharepointDetails(e, index)}
            inputCommand={item.inputCommand}
            iconStyle={{paddingRight: '6px'}}
            icon={EntitlementIcon()}
            isOverlay={true}
            overlayName={overlayName}
          />)}
        </SharepointWrapper>}

        {/* <AddMemberLabel>
          <Button
            type="tertiary"
            text="Add a user [ + ]"
            onClick={() => addMemberfunction()}
            buttonStyle={{
              border: "1px solid #F2F2F2",
              padding: "4px 12px",
              height: "26px",
            }}
            data-speech={speechFormat({
              key: `+`,
              action: "add",
              item: "blank user row",
            })}
          />
        </AddMemberLabel> */}
      </div>
    </AddClientModalWrapper>
  );
};

export default AddClientModal;
