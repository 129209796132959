import React, { useEffect, useState } from "react";
import {
  Body,
  DocumentBody,
  Frame,
  HeadRight,
  HeadTitle,
  HeaderWrapper,
  Left,
  Middle,
  PaginationContainer,
  PairWrapper,
  PreviewBody,
  PreviewFooter,
  PreviewHeader,
  PreviewSubtext,
  PreviewTitle,
  Right,
  SearchContainer,
  Wrapper,
  UploadContainer
} from "./ProtocolModal.styled";
import Button from "../../components/Button/Button";
import { PairTitle, ButtonWrapper } from "./ProtocolModal.styled";
import Search from "../../components/Search/Search";
import DocumentItem from "../../components/DocumentItem/DocumentItem";
import Pagination from "../../components/Pagination/Pagination";
import InputField from "../../components/InputField/InputField";
import SelectProtocolIcon from "../../assets/svgs/SelectProtocolIcon";
import {
  getDestinationTemplate,
  getSourceTemplate,
  getPreviewPDFFile,
  getPreviewSharepointPDFFile,
  getInputDocumentList,
  uploadDocToSharepoint,
  uploadStyleDocument,
  uploadSourceDocument,
  getPDFList,
  getProtocolDocumentList
} from "../../apis/ApiServerRequests";
import { Document, Page, pdfjs } from "react-pdf";
import NoPreview from "../../assets/NoPreview.png";
import NoSharePoint from "../../assets/NoSharePoint.png";
import { connect } from "react-redux";
import SkeletonLoader from "../../components/SkeletonLoader/SkeletonLoader";
import useKeyPress from "../../hooks/useKeyPress";
import NoSearchFound from "../../components/NoSearchFound/NoSearchFound";
import speechFormat from "../../helpers/speechFormat";
import NoPreviewFound from "../../components/NoPreviewFound/NoPreviewFound";
import SharePointInfo from "../../components/SharePointInfo/SharePointInfo";
import Accordion from "../../components/Accordion/Accordion";
import FolderIcon from "../../assets/svgs/FolderIcon";
import DeleteIcon from "../../assets/svgs/DeleteIcon";
import UploadDoc from "../../components/UploadDoc/UploadDoc";
import SuccessPopup from "../../components/SuccesPopup/SuccessPopup";
import ModalWrapper from "../../components/ModalWrapper/ModalWrapper";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const ProtocolModal = ({
  onClose,
  templateType,
  setTemplateType,
  selectDocFile,
  enablePreview,
  pairComponents,
  title,
  protocolDocOptions,
  is_contrast,
  protocolDocLoading,
  overlayName,
  pharmaName,
  selectedSourceTemplateAndDocument,
  setSelectedSourceTemplateAndDocument,
  setIsFolder,
  setSelectedInputFile,
  setSelectedProtocolDoc,
  acceptedFileType,
  selectedDocumentCategory=null,
  setSelectedDocumentCategory
}) => {
  const perPage = 4;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [loading, setLoading] = useState(false);
  const [docUploading, setDocUploading] = useState(false);
  const [fileBlob, setFileBlob] = useState(null);
  const [numPages, setNumPages] = useState(null);

  const [input, setInput] = useState("");
  const [itemList, setItemList] = useState([]);
  const [filenameList, setFilenameList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedAccordion, setSelectedAccordion] = useState(-1)
  const [apiData, setApiData] = useState(null)

  const [selectedProtocolFile, setSelectedProtocolFile] = useState("");
  const [pairs, setPairs] = useState(selectedSourceTemplateAndDocument || [{ id: 1, sourceTemplate: "", sourceDocument: "" }]);
  const [selectedPair, setSelectedPair] = useState(null);
  const [selectedFileToUpload, setSelectedFileToUpload] = useState(null);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [docUploadErrorMsg, setDocUploadErrorMessage] = useState(false)
  const location = useLocation();
  const styled = location.pathname.includes("format");

  const client_list = useSelector((state) => state?.client?.client_list);

  const addPair = () => {
    setPairs(prevPairs => [
      ...prevPairs,
      { id: prevPairs.length + 1, sourceTemplate: "", sourceDocument: "" }
    ]);
    setSelectedPair(null)
  };

  const handleInputClick = (pair, name) => {
    let fileType = (name === "sourceTemplate") ? "source_templates" : "protocol_Doc"
    if (templateType !== fileType)  setItemList([])
    setTemplateType(fileType)
    setSelectedPair(pair)
  };

  const handleSelection = (i) => {
    setSelectedItem(i);
  };


  let debounceTimeout;


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setTotalPages(0);
      setSelectedItem(null);
      setFileBlob(null);
      setInput("");
  
      let data = [];
      try {
        switch (templateType) {
          case "destination_templates":
            const destinationData = await getDestinationTemplate(pharmaName);
            data = destinationData.data?.destination || [];
            break;
  
          case "source_templates":
            const sourceData = await getSourceTemplate(pharmaName);
            data = sourceData.data?.source || [];
            break;
  
          case "input_document":
            const inputDocData = await getInputDocumentList(pharmaName);
            data = inputDocData.data || [];
            break;
  
          case "select_pharmacy":
            data = client_list.map((client) => client.pharmaName) || [];
            break;
  
          case "redact_file":
            if (pharmaName) {
              const redactFileData = await getPDFList(pharmaName);
              data = redactFileData.data?.source_file || [];
            }
            break;
  
          case "redact_keyword_file":
            if (pharmaName) {
              const redactKeywordData = await getPDFList(pharmaName, "Redact_keywords_source");
              data = redactKeywordData.data?.source_file || [];
            }
            break;
  
          case "protocol_Doc":
            const protocolDocData = await getProtocolDocumentList(pharmaName)
            data = protocolDocData.data?.source_file || [];
            setLoading(protocolDocLoading);
            break;
  
          default:
            break;
        }
  
        setApiData(data)
        
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
  
    if (!loading) {
      clearTimeout(debounceTimeout);

      debounceTimeout = setTimeout(() => {
        fetchData();
      }, 300); // Adjust debounce time as necessary

      return () => clearTimeout(debounceTimeout);
    }

  }, [templateType]);
  

  useEffect(() => {
    setFileBlob(null)
    const handleDownloadAndDisplay = (name, isFolder = false) => {
      let template = "Source Templates";
      if (templateType === "destination_templates" && name !== "undefined") {
        template = "Destination Templates";
        getPreviewPDFFile(template, selectedDocumentCategory, name).then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          setFileBlob(blob);
        })
        .catch((error) => {
          console.error("Error fetching destination template PDF:", error);
        });
      }

      else if (templateType === "protocol_Doc" && name !== "undefined") {
        getPreviewSharepointPDFFile(pharmaName, selectedDocumentCategory, name, isFolder, "Source Templates").then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          setFileBlob(blob);
        })
        .catch((error) => {
          console.error("Error fetching protocol document PDF:", error);
        });
      }

      else if (templateType === "input_document" && name !== "undefined") {
        getPreviewSharepointPDFFile(pharmaName, selectedDocumentCategory, name, isFolder, "Formatting Documents").then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          setFileBlob(blob);
        })
        .catch((error) => {
          console.error("Error fetching input document PDF:", error);
        });
      }

      else {
        if (name !== "undefined") {
          getPreviewPDFFile(template, selectedDocumentCategory, name).then((response) => {
            const blob = new Blob([response.data], { type: "application/pdf" });
            setFileBlob(blob);
          })
          .catch((error) => {
            console.error("Error fetching default PDF:", error);
          });
        }
      }
    };

    if (selectedItem != null) {

      if (
        templateType === "destination_templates"
      ) {
        selectDocFile(itemList[selectedAccordion]?.versions[selectedItem]?.name !== "undefined" ? itemList[selectedAccordion]?.versions[selectedItem]?.name : "");
        setIsFolder(itemList[selectedAccordion]?.versions[selectedItem]?.type === 'folder')
        handleDownloadAndDisplay(`${itemList[selectedAccordion]?.versions[selectedItem]?.name}`, itemList[selectedAccordion]?.versions[selectedItem]?.type === 'folder');
      }

      if (
        templateType === "input_document"
      ) {
        selectDocFile(`${itemList[selectedAccordion]?.versions[selectedItem]?.name}`);
        setIsFolder(itemList[selectedAccordion]?.versions[selectedItem]?.type === 'folder')
        handleDownloadAndDisplay(`${itemList[selectedAccordion]?.versions[selectedItem]?.name}`, itemList[selectedAccordion]?.versions[selectedItem]?.type === 'folder');
      }

      if (
        templateType === "redact_file" || 
         templateType === "redact_keyword_file"
      ) {
        selectDocFile(`${itemList[selectedAccordion]?.versions[selectedItem]?.name}`);
        setSelectedFileToUpload(null)
      }

      if (templateType === "source_templates" ||
        templateType === "protocol_Doc") {
        let copypair = [...pairs]
        let selectedPairValue = copypair.find((item) => item.id === selectedPair.id)

        if (templateType === "protocol_Doc") {
          selectedPairValue.sourceDocument = itemList[selectedAccordion]?.versions[selectedItem]?.name !== undefined ? itemList[selectedAccordion]?.versions[selectedItem]?.name : ""
        }

        if (templateType === "source_templates") {
          selectedPairValue.sourceTemplate = itemList[selectedAccordion]?.versions[selectedItem]?.name !== undefined ? itemList[selectedAccordion]?.versions[selectedItem]?.name : ""
        }

        setSelectedSourceTemplateAndDocument(copypair)
        handleDownloadAndDisplay(`${itemList[selectedAccordion]?.versions[selectedItem]?.name}`, itemList[selectedAccordion]?.versions[selectedItem]?.type === 'folder');
        selectDocFile(`${itemList[selectedAccordion]?.versions[selectedItem]?.name}`);
        setIsFolder(itemList[selectedAccordion]?.versions[selectedItem]?.type === 'folder')
      }

      if (
        templateType === "select_pharmacy"
      ) {
        selectDocFile(itemList[selectedItem]);
        handleEsc();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  useEffect(() => {

    if (filenameList.length > 0) {
      if (
        templateType === "source_templates" ||
        templateType === "destination_templates" ||
        templateType === "protocol_Doc" ||
        templateType === "input_document" ||
        templateType === "redact_file" ||
        templateType === "redact_keyword_file"
      ) {
        let filterList = filenameList?.filter((item) =>
          item?.name.toLowerCase().includes((input || "").toLowerCase())
        );
        let activeList = filterList?.slice(
          (currentPage - 1) * perPage,
          currentPage * perPage
        );
        setItemList(activeList);
        setTotalPages(
          Math.ceil(filterList?.length ? filterList?.length / perPage : 0)
        );
      } else {
        let filterList = filenameList?.filter((item) =>
          item.toLowerCase().includes((input || "").toLowerCase())
        );
        let activeList = filterList?.slice(
          (currentPage - 1) * perPage,
          currentPage * perPage
        );
  
        setItemList(activeList);
        setTotalPages(
          Math.ceil(filterList?.length ? filterList?.length / perPage : 0)
        );
      }
    }

  }, [input, currentPage, filenameList]);

  useEffect(() => {
    setLoading(true)
    setFileBlob(null);
    setSelectedAccordion(-1)
    setSelectedItem(null)
    if (apiData) {
      try {
        if (selectedDocumentCategory === "Clinical" && ["destination_templates", "source_templates", "protocol_Doc"].includes(templateType)) {
          if (apiData?.clinical) {
            setFilenameList(apiData?.clinical);
            setTotalPages(Math.ceil(apiData?.clinical.length / perPage));
          }
        } else if (selectedDocumentCategory === "Non-clinical" && ["destination_templates", "source_templates", "protocol_Doc"].includes(templateType)) {
          if (apiData?.["non-clinical"]) {
            setFilenameList(apiData["non-clinical"]);
            setTotalPages(Math.ceil(apiData["non-clinical"].length / perPage));
          }
        } else {
          setFilenameList(apiData)
          setTotalPages(Math.ceil(apiData.length / perPage));
        }
        setCurrentPage(1)
        setLoading(false);
      } catch (error) {
        
      }
    }
  }, [selectedDocumentCategory, templateType, apiData]);

  useEffect(() => {
    if (selectedItem !== null && selectedFileToUpload) {
      setSelectedItem(null)
      setSelectedAccordion(-1)
    }

  }, [selectedFileToUpload])

  useEffect(() => {
    setCurrentPage(1);
  }, [input]);

  useEffect(() => {
    setSelectedItem(null);
  }, [currentPage]);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const fetchInput = (val) => {
    setInput(val);
  };

  const validateAddDocument = (arr) => {
    if (!Array.isArray(arr)) {
      return false;
    }

    for (const obj of arr) {
      if (typeof obj !== 'object' || obj === null) {
        return false;
      }
      for (const key in obj) {
        if (obj.hasOwnProperty(key) && (obj[key] === null || obj[key] === undefined || obj[key] === '')) {
          return false;
        }
      }
    }
    return true;
  }

  const handleEnter = () => {
    if (selectedFileToUpload) {
      if (styled) {
        const formData = new FormData();
        formData.append("file", selectedFileToUpload);
        setDocUploading(true)
        setSuccessModalOpen(true);
        uploadStyleDocument(pharmaName, formData).then((response) => {
          setDocUploading(false)
          setSelectedInputFile(selectedFileToUpload.name)
          setSelectedFileToUpload(null)
          setTimeout(() => {
            onClose(true)
          }, 2000)
        })
          .catch((error) => {
            setDocUploading(false)
            setDocUploadErrorMessage(true);
            setSuccessModalOpen(true);
            setSelectedFileToUpload(null);
            console.error("Error uploading file:", error);
          });
      }
      if (!styled && !["redact_file", "redact_keyword_file"].includes(templateType)) {
        const formData = new FormData();
        formData.append("file", selectedFileToUpload);
        setDocUploading(true)
        setSuccessModalOpen(true);
        uploadSourceDocument(pharmaName, formData).then((response) => {
          setDocUploading(false)
          setSelectedFileToUpload(null)
          let copypair = [...pairs]
          let selectedPairValue = copypair.find((item) => item.id === selectedPair.id)
          selectedPairValue.sourceDocument = selectedFileToUpload.name;
          setSelectedSourceTemplateAndDocument(copypair)
          setSelectedProtocolDoc(selectedFileToUpload.name)
        })
          .catch((error) => {
            setDocUploading(false)
            setDocUploadErrorMessage(true)
            setSuccessModalOpen(true);
            setSelectedFileToUpload(null)
            console.error("Error uploading file:", error);
          });

      }

      if (["redact_file", "redact_keyword_file"].includes(templateType)) {
        selectDocFile(selectedFileToUpload?.name)
        setSelectedInputFile(templateType, selectedFileToUpload)
        onClose(true)
      }

    } else {
      console.log("Handle other logic here");
      onClose(true)
    }
  };
  const handleEsc = () => onClose(false)

  useKeyPress("Escape", [], handleEsc, true, overlayName, true);
  useKeyPress("Enter", [], handleEnter, true, overlayName, true);

  // useKeyPress("1", ["ctrlKey"], () => handleSelection(0), true, overlayName);
  // useKeyPress("2", ["ctrlKey"], () => handleSelection(1), true, overlayName);
  // useKeyPress("3", ["ctrlKey"], () => handleSelection(2), true, overlayName);
  // useKeyPress("4", ["ctrlKey"], () => handleSelection(3), true, overlayName);
  useKeyPress("C", ["altKey"], () => setSelectedDocumentCategory("clinical"), true, overlayName, true);
  useKeyPress("N", ["altKey"], () => setSelectedDocumentCategory("non-clinical"), true, overlayName, true);

  // const handleUseKeyPress =(index)=>{
  //   useKeyPress(index, ["ctrlKey"], () => handleSelection(index+1), true, overlayName);
  // }

  // itemList.map((item, index) => {
  //   handleUseKeyPress(index)
  // })

  useEffect(() => {
    if (itemList.length > 0) {
      itemList.forEach((item, index) => {
        const handleKeyPress = (event) => {
          if (event.key === `${index + 1}` && event.ctrlKey) {
            event.preventDefault();
            handleSelection(index);
          }
        };
        window.addEventListener("keydown", handleKeyPress);
        return () => {
          window.removeEventListener("keydown", handleKeyPress);
        };
      });
    }
  }, [itemList, handleSelection]);


  useKeyPress("K", ["altKey"], () => setInput(""), true, overlayName);
  useKeyPress("A", ["altKey"], addPair, true, overlayName, true);

  return (
    <Wrapper width={pairComponents ? "1296" : enablePreview ? "884" : "440"}>
      <HeaderWrapper>
        <HeadTitle>{title.toUpperCase()}</HeadTitle>
        <HeadRight>
          <Button
            type={"ghost"}
            text={"Cancel"}
            onClick={handleEsc}
            accessKeyText={"[Esc]"}
            data-speech={speechFormat({
              key: "Escape",
              action: "close",
              item: "Dialog",
            })}
          />
          
          <Button
            disabled={pairComponents ? !validateAddDocument(pairs) && !selectedFileToUpload : selectedItem === null && !selectedFileToUpload}
            type={"secondary"}
            text={enablePreview ? "Done" : "Continue"}
            onClick={handleEnter}
            accessKeyText={"[Enter]"}
            speech={speechFormat({
              key: "Enter",
              action: "select",
              item: templateType === "select_pharmacy" ? "Client" : "Doc",
            })}
          />
        </HeadRight>
      </HeaderWrapper>
      <Body>
        {pairComponents ? (
          <Left>
            {pairs.map((pair, index) => (
              <PairWrapper key={pair.id}>
                <PairTitle>Pair {index + 1}</PairTitle>
                <InputField
                  focus={true}
                  width={"400"}
                  wrapperStyle={{ marginBottom: "24px" }}
                  value={pair.sourceTemplate !== "Undefined" ? pair.sourceTemplate : ""}
                  onFocus={() => handleInputClick(pair, "sourceTemplate")}
                  onClick={() => handleInputClick(pair, "sourceTemplate")}
                  labelStyle={{
                    position: "absolute",
                    padding: "4px 50px",
                  }}
                  label={"Select Source Template"}
                  inputStyle={{ paddingBottom: "10px", height: "34px" }}
                  icon={SelectProtocolIcon()}
                  // rightIcon={pair.sourceTemplate ? DeleteIcon(): null}
                  // inputCommand={{
                  //   text: `[Ctrl + ${index + 1 + index}]`,
                  //   key: "3",
                  //   subkey: ["ctrlKey"],
                  // }}
                  data-speech={speechFormat({
                    key: "Alt + 4",
                    action: "",
                    item: "Select Source Template",
                  })}
                />
                <InputField
                  width={"400"}
                  wrapperStyle={{ marginBottom: "24px" }}
                  value={pair.sourceDocument !== "undefined" ? pair.sourceDocument : ""}
                  // rightIcon={pair.sourceDocument ? DeleteIcon() : null}
                  // inputCommand={{
                  //   text: `[Ctrl + ${index + 2 + index}]`,
                  //   key: "3",
                  //   subkey: ["ctrlKey"],
                  // }}
                  labelStyle={{
                    position: "absolute",
                    padding: "4px 50px",
                  }}
                  label={"Select Source Document"}
                  onFocus={() => handleInputClick(pair, "sourceDocument")}
                  onClick={() => handleInputClick(pair, "sourceDocument")}
                  inputStyle={{ paddingBottom: "10px", height: "34px" }}
                  icon={SelectProtocolIcon()}
                  data-speech={speechFormat({
                    key: "Alt + 4",
                    action: "",
                    item: "Select Source Document",
                  })}
                />
              </PairWrapper>
            ))}
            <ButtonWrapper>
              <Button
                disabled={!selectedSourceTemplateAndDocument ? !validateAddDocument(pairs) : !validateAddDocument(pairs)}
                type="tertiary"
                text="+  Add Documents "
                accessKeyText="[ Alt + A ]"
                onClick={addPair}
                buttonStyle={{ marginLeft: 10 }}
                data-speech={speechFormat({
                  key: "Alt + A",
                  action: "",
                  item: "Add a Company",
                })}
              />
            </ButtonWrapper>
          </Left>
        ) : null}
        <Middle preview={enablePreview}>
          {(templateType === "protocol_Doc" || templateType === "input_document" || templateType === "redact_file" || templateType === "redact_keyword_file") &&
            <UploadContainer>
              <UploadDoc width={templateType === "select_pharmacy" || templateType === "source_templates"}
                righticons={true} rightUploadButton={true} setSelectedFile={setSelectedFileToUpload} selectedFile={selectedFileToUpload}
                acceptedFileType={acceptedFileType}
              />
              <div style={{ marginTop: '10px', fontSize: '12px', color: 'gray', display: 'flex', justifyContent: 'center' }}>OR</div>
            </UploadContainer>
          }
          {!["redact_file", "input_document"].includes(templateType) && <ButtonWrapper
           style={{
            width: templateType === "select_pharmacy" ? "0" : "410px",
            display: templateType === "select_pharmacy" ? "none" : "flex",
            justifyContent: "center",
            alignItems: "center",
            rowGap: "10px",
            border: "1px solid #d3d3d3",
            borderRadius: "21px", 
            padding: "4px"
          }}
          >
              <Button
                type={selectedDocumentCategory == "Clinical"? "brand": "ghost"}
                text="Clinical"
                accessKeyText="[ Alt + C ]"
                onClick={() => setSelectedDocumentCategory("Clinical")}
                buttonStyle={{
                  cursor: (templateType === "source_templates" || templateType === "protocol_Doc") && selectedDocumentCategory === "Non-clinical"? "not-allowed": "pointer",
                  pointerEvents: (templateType === "source_templates" || templateType === "protocol_Doc") && selectedDocumentCategory === "Non-clinical"? "none": "auto",
                  display: "flex",
                  flex: "1",
                  justifyContent: 'center'
                }}
                textStyle={{
                  fontWeight: "bold",
                  textAlign: "center"
                }}
                ContentStyle={{
                  width: "auto"
                }}
                data-speech={speechFormat({
                  key: "Alt + C",
                  action: "show",
                  item: "Clinical documents",
                })}
              />
              
              <Button
                type={selectedDocumentCategory == "Clinical"? "ghost": "brand"}
                text="Non-Clinical"
                accessKeyText="[ Alt + N ]"
                onClick={() => setSelectedDocumentCategory("Non-clinical")}
                buttonStyle={{
                  cursor: (templateType === "source_templates" || templateType === "protocol_Doc") && selectedDocumentCategory === "Clinical"? "not-allowed": "pointer",
                  pointerEvents: (templateType === "source_templates" || templateType === "protocol_Doc") && selectedDocumentCategory === "Clinical"? "none": "auto",
                  display: "flex",
                  flex: "1",
                  justifyContent: 'center'
                }}
                textStyle={{
                  fontWeight: "bold"
                }}
                ContentStyle={{
                  width: "auto"
                }}
                data-speech={speechFormat({
                  key: "Alt + N",
                  action: "show",
                  item: "Non-Clinical documents",
                })}
              />
          </ButtonWrapper>
          }
          <SearchContainer>
            <Search
              wrapperStyle={{
                width:
                  templateType === "select_pharmacy" ||
                    templateType === "protocol_Doc"
                    ? "420px"
                    : "420px",
              }}
              width={
                templateType === "select_pharmacy" ||
                  templateType === "source_templates"
                  ? "420"
                  : "420"
              }
              placeholdertext={
                templateType === "select_pharmacy"
                  ? "Search Clients..."
                  : "Search Docs..."
              }
              righticons={true}
              onChange={fetchInput}
              value={input}
              searchCommand={{
                text: "X",
                key: "X",
                subkey: [],
              }}
              voiceCommand={{
                text: "[ Alt + X ]",
                key: "X",
                subkey: ["altKey"],
              }}
              overlayName={overlayName}
              isOverlay={true}
              speech={speechFormat({
                action: "search",
                item: templateType === "select_pharmacy" ? "Clients" : "Docs",
              })}
            />
            {
              (templateType === "source_templates" || templateType === "protocol_Doc" || templateType === "destination_templates") &&
              <PreviewSubtext is_contrast={is_contrast}>
                These documents are from Selected Client’s Sharepoint
              </PreviewSubtext>
            }
          </SearchContainer>
          <DocumentBody>
            {loading ? (
              <div style={{ width: "100%" }}>
                <SkeletonLoader count={perPage} height={74} />
              </div>
            ) : (
              <>
                {templateType === "protocol_Doc" &&
                  filenameList.length === 0 && <SharePointInfo />}
                {templateType === "source_templates" &&
                  filenameList.length === 0 && <SharePointInfo />}
                {input && filenameList.length > 0 && itemList.length === 0 ? (
                  <NoSearchFound
                    height={"336px"}
                    resetSearch={() => setInput("")}
                  />
                ) :

                  templateType === "select_pharmacy" ? (
                    itemList?.map((item, index) => {
                      return (
                        <DocumentItem
                          index={index}
                          text={item}
                          type={item?.type}
                          selectedItemIndex={selectedItem}
                          setSelectedItemIndex={handleSelection}
                          wrapperStyle={{ "padding": "8px 12px" }}
                          showIcon={
                            templateType === "select_pharmacy" ? false : true
                          }
                          labelText={
                            templateType === "select_pharmacy"
                              ? "Select this client"
                              : "Select this doc"
                          }
                        // date={item.date}
                        ></DocumentItem>
                      )
                    })
                  )
                    :
                    itemList.map((item, index) => {
                      return (
                        <Accordion title={item?.name} key={index} sno={index} content={item?.versions} icon={FolderIcon()}
                          selectedItem={selectedItem}
                          selectedAccordion={selectedAccordion}
                          setSelectedAccordion={setSelectedAccordion}
                          handleSelection={handleSelection}
                          overlayName={templateType}
                          isOverlay={true}
                          templateType={templateType}
                        />
                      );
                    })
                }
              </>
            )
            }
          </DocumentBody>
          <PaginationContainer>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onFirstPage={() => setCurrentPage(1)}
              onLastPage={() => setCurrentPage(totalPages)}
              onPrev={handlePrevPage}
              onNext={handleNextPage}
              firstAccessKey={{
                text: "[;]",
                key: ";",
                subkey: [],
                speech: speechFormat({
                  key: "Semicolon",
                  action: "goto",
                  item: "First page",
                }),
              }}
              prevAccessKey={{
                text: "[,]",
                key: ",",
                subkey: [],
                speech: speechFormat({
                  key: "comma",
                  action: "goto",
                  item: "Previous page",
                }),
              }}
              nextAccessKey={{
                text: "[.]",
                key: ".",
                subkey: [],
                speech: speechFormat({
                  key: "fullstop",
                  action: "goto",
                  item: "Next page",
                }),
              }}
              lastAccesskey={{
                text: "[']",
                key: "'",
                subkey: [],
                speech: speechFormat({
                  key: "single quote",
                  action: "goto",
                  item: "Last page",
                }),
              }}
              isOverlay={true}
              overlayName={templateType}
            />
          </PaginationContainer>
        </Middle>

        {enablePreview ? (
          <Right>
            <PreviewHeader>
              <PreviewTitle>Preview of the Selected Doc</PreviewTitle>
              {/* <PreviewSubtext is_contrast={is_contrast}>
                {templateType === "select_pharmacy" || templateType === "protocol_Doc" && selectedItem >= 0 ? `${filenameList[selectedItem]}` : `${itemList[selectedAccordion]?.name} > ${templateType === "destination_templates" ? "Destination Document" : "Source Document"} - ${itemList[selectedAccordion]?.versions[selectedItem]}.docx`}
              </PreviewSubtext> */}
            </PreviewHeader>
            <PreviewBody>
              {selectedItem === null ? (
                <NoPreviewFound />
              ) : (
                <>
                  {(fileBlob && (
                    <Document
                      file={fileBlob}
                      onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                    >
                      {Array.from(new Array(numPages), (el, index) => (
                        <Page
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                          renderAnnotationLayer={false}
                          renderTextLayer={false}
                        />
                      ))}
                    </Document>
                  )) || (
                      <div style={{ width: "100%" }}>
                        <SkeletonLoader height={340} />
                      </div>
                    )}
                </>
              )}
            </PreviewBody>
            {selectedItem ? (
              <PreviewFooter>
                <Button
                  type={"ghost"}
                  text={"Scroll Up"}
                  buttonStyle={{ padding: "20px 0px" }}
                  accessKeyText={"[Page Up]"}
                />
                <Button
                  type={"ghost"}
                  text={"Scroll Down"}
                  buttonStyle={{ padding: "20px 0px" }}
                  accessKeyText={"[Page Dn]"}
                />
              </PreviewFooter>
            ) : null}
          </Right>
        ) : null}
      </Body>
      {successModalOpen && (
        <SuccessPopup msg_processing={"Uploading Document, please wait..."} msg_failed={`Upload failed. Please try again`} msg_done={`Document uploaded succesfully`} onClose={() => setSuccessModalOpen(false)} docUploadErrorMsg={docUploadErrorMsg} setDocUploadErrorMessage={setDocUploadErrorMessage} loading={docUploading} />
      )}
    </Wrapper >
  );
};

const mapStateToProps = (state) => {
  return {
    is_contrast: state?.a11y?.high_contrast,
  };
};

export default connect(mapStateToProps)(ProtocolModal);
